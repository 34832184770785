import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smallNumber',
  standalone: true,
})
export class SmallNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | undefined, showUnitDecimal: boolean = true): string {
    if (value === undefined) {
      return '';
    }

    let isNegative = false;
    if (value < 0) {
      isNegative = true;
      value = Math.abs(value);
    }

    let result: string;
    if (value < 10) {
      result = this.decimalPipe.transform(value, showUnitDecimal ? '1.1-1' : '1.0-0') ?? '?';
    } else if (value < 1000) {
      result = this.decimalPipe.transform(value, '1.0-0') ?? '?';
    } else if (value < 100000) {
      result = (this.decimalPipe.transform(value/1000, '1.1-1') ?? '?') + 'k';
    } else if (value < 1000000) { // Less than a million
      result = (this.decimalPipe.transform(value / 1000, '1.0-0') ?? '?') + 'k'; 
    } else if (value < 100000000) {
      result = (this.decimalPipe.transform(value / 1000000, '1.1-1') ?? '?') + 'M'; 
    } else if (value < 1000000000) {
      result = (this.decimalPipe.transform(value / 1000000, '1.0-0') ?? '?')+ 'M'; 
    } else {
      result = (this.decimalPipe.transform(value / 1000000000, '1.1-1') ?? '?') + 'B'; 
    }

    return isNegative ? '-' + result : result;
  }
}
